import { failure, success } from "../../../domain";
import type {
  VerifyDeviceUseCase,
  VerifyDeviceUseCaseRequest,
  VerifyDeviceUseCaseResponse,
  VerifyDeviceUseCaseResponseDTO,
} from "../../../domain/use-cases/trusted-devices";
import { HTTP_STATUS_CODES, HttpClient } from "../../http";

export class VerifyDeviceUseCaseImpl implements VerifyDeviceUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
  ) {}

  async execute(
    params: VerifyDeviceUseCaseRequest,
  ): Promise<VerifyDeviceUseCaseResponse> {
    const response =
      await this.httpClient.request<VerifyDeviceUseCaseResponseDTO>({
        method: "GET",
        url: this.url,
        params,
        headers: {
          "Content-Type": "application/json",
        },
      });

    switch (response.statusCode) {
      case HTTP_STATUS_CODES.ok:
        return success(response.body);
      case HTTP_STATUS_CODES.badRequest:
        return failure("Dispositivo inválido.");
      case HTTP_STATUS_CODES.unauthorized:
        return failure("Usuário não autorizado.");
      default:
        return failure("Erro ao verificar dispositivo. Tente novamente.");
    }
  }
}
