import { failure, success } from "../../../domain";
import type {
  RegisterDeviceUseCase,
  RegisterDeviceUseCaseRequest,
  RegisterDeviceUseCaseResponse,
} from "../../../domain/use-cases/trusted-devices";
import { HttpClient } from "../../http";

export class RegisterDeviceUseCaseImpl implements RegisterDeviceUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
  ) {}

  async execute(
    data: RegisterDeviceUseCaseRequest,
  ): Promise<RegisterDeviceUseCaseResponse> {
    const response =
      await this.httpClient.request<RegisterDeviceUseCaseResponse>({
        method: "POST",
        url: this.url,
        body: data,
      });

    if (response.statusCode >= 200 && response.statusCode <= 299) {
      return success(undefined);
    }

    return failure(response.body) as RegisterDeviceUseCaseResponse;
  }
}
