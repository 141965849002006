import { queryOptions } from "@tanstack/react-query";
import { VerifyDeviceUseCaseRequest } from "../../../domain/use-cases/device-fingerprint";
import { verifyDeviceUseCase } from "../../../main/factories/use-cases/device-fingerprint/verify-device";

async function verifyDevice(deviceId: string) {
  const verified = await verifyDeviceUseCase.execute({
    deviceId,
  });

  if (verified.isFailure()) {
    throw new Error(verified.value);
  }

  return verified.value;
}

export const deviceQueryFactory = {
  default: ["device"] as const,
  verify: (params: VerifyDeviceUseCaseRequest) =>
    queryOptions({
      queryKey: [...deviceQueryFactory.default, "verify", params.deviceId],
      queryFn: () => verifyDevice(params.deviceId),
      staleTime: Infinity,
    }),
};
