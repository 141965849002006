import { useCallback } from "react";
import html2canvas from "html2canvas";
import JSPdf from "jspdf";
import { useSendNativeEvent } from "../use-send-native-event";

export const useSendPDFtoMobile = () => {
  const { isNative, sendMessage } = useSendNativeEvent();
  const generateAndSendPDF = useCallback(
    async (element: HTMLElement, filename = "comprovante-pix.pdf") => {
      if (!element) return;
      const originalOverflow = element.style.overflow;
      try {
        element.style.overflow = "visible";

        await new Promise((resolve) => setTimeout(resolve, 300));
        const scaleFactor = Math.min(2, window.devicePixelRatio);
        const canvas = await html2canvas(element, {
          useCORS: true,
          scale: scaleFactor,
          backgroundColor: "#fff",
          width: element.scrollWidth,
          height: element.scrollHeight,
        });

        const imgData = canvas.toDataURL("image/png");

        const pdf = new JSPdf("p", "mm", "a4");
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const ratio = Math.min(
          pageWidth / canvas.width,
          pageHeight / canvas.height,
        );

        const imgWidth = canvas.width * ratio;
        const imgHeight = canvas.height * ratio;

        pdf.addImage(
          imgData,
          "PNG",
          (pageWidth - imgWidth) / 2,
          (pageHeight - imgHeight) / 2,
          imgWidth,
          imgHeight,
        );
        const pdfBase64 = pdf.output("datauristring");

        if (isNative) {
          sendMessage("PDF_GENERATED", { base64: pdfBase64, filename });
        }
      } catch (error) {
        console.error("Erro ao gerar e enviar PDF:", error);
      } finally {
        element.style.overflow = originalOverflow;
      }
    },
    [isNative, sendMessage],
  );

  return { generateAndSendPDF };
};
