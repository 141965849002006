import { ACCOUNT_MANAGEMENT_API_URL } from "../../../../config";
import type { RegisterDeviceUseCase } from "../../../../domain/use-cases/trusted-devices";
import { RegisterDeviceUseCaseImpl } from "../../../../infra";
import { makeAccountIdHttpClientDecoratorFactory } from "../../decorators/make-account-id-http-client-decorator-factory";
import { makeApiUrlFactory } from "../../http";

export const makeRegisterDeviceUseCaseFactory = (): RegisterDeviceUseCase => {
  return new RegisterDeviceUseCaseImpl(
    makeAccountIdHttpClientDecoratorFactory(),
    makeApiUrlFactory({
      path: "api/Device",
      baseUrl: ACCOUNT_MANAGEMENT_API_URL,
    }),
  );
};

export const registerDeviceUseCase = makeRegisterDeviceUseCaseFactory();
