/* eslint-disable @typescript-eslint/no-explicit-any */
import { AllRoutes } from "../../../store/Routes/get-all-routes";
import type { Context, DebuggingService } from "./debugging-service";
import type { RouteBasedDebuggingService } from "./route-based-debugging-service";

export class DebuggingServiceImpl
  implements DebuggingService, RouteBasedDebuggingService
{
  constructor(
    private readonly debuggingService: DebuggingService,
    private readonly applicationRoutes: AllRoutes,
  ) {}

  private isServiceInitialized = false;

  public init(): void {
    this.debuggingService.init();
    this.isServiceInitialized = true;
  }

  public close(): void {
    this.debuggingService.close();
    this.isServiceInitialized = false;
  }

  public captureException(error: any, context: Context): void {
    this.debuggingService.captureException(error, context);
  }

  public handleRouteChange(route: string): void {
    if (this.isAllowedRoute(route)) {
      if (!this.isServiceInitialized) {
        this.init();
      }
    } else {
      this.close();
    }
  }

  public listenToRouteChanges(): void {
    this.handleRouteChange(window.location.pathname);

    window.addEventListener("popstate", () => {
      this.handleRouteChange(window.location.pathname);
    });
  }

  private isAllowedRoute(route: string): boolean {
    const allowedRoutes = this.applicationRoutesToList();
    return allowedRoutes.some((allowedRoute) => {
      const routeRegex = new RegExp(
        `^${allowedRoute.replace(/:\w+/g, "[^/]+")}$`,
      );
      return routeRegex.test(route);
    });
  }

  private applicationRoutesToList(): string[] {
    const rootRoute = "/";
    const loginRoute = "/session/login";
    return [
      ...new Set(
        Object.values(this.applicationRoutes).flatMap((routes) =>
          Object.values(routes).filter(
            (route) => route !== loginRoute || route === rootRoute,
          ),
        ),
      ),
    ];
  }
}
