import type {
  ListDeviceUseCase,
  ListDeviceUseCaseErrorResponse,
  ListDeviceUseCaseRequest,
  ListDeviceUseCaseResponse,
  ListDeviceUseCaseSuccessResponse,
} from "../../../domain/use-cases/trusted-devices/list-devices-usecase";
import type { HttpClient } from "../../http";

export class ListDeviceUseCaseImpl implements ListDeviceUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
  ) {}

  async execute(
    params: ListDeviceUseCaseRequest,
  ): Promise<ListDeviceUseCaseResponse> {
    try {
      if (!params.personId) {
        return {
          success: false,
          error: {
            code: "NO_PERSON_ID",
            errorMessages: ["Person ID not found in account store"],
          },
        };
      }

      const response =
        await this.httpClient.request<ListDeviceUseCaseSuccessResponse>({
          method: "GET",
          url: this.url,
          params: {
            personId: params.personId,
            page: params.page,
            itensPage: params.itemsPerPage as string,
            deviceStatus: params.status as string,
          },
        });

      if (response.statusCode >= 400) {
        return {
          success: false,
          error: response.body as unknown as ListDeviceUseCaseErrorResponse,
        };
      }

      return {
        success: true,
        data: response.body,
      };
    } catch (error) {
      return {
        success: false,
        error: {
          code: "UNKNOWN_ERROR",
          errorMessages: [
            (error as Error).message || "An unknown error occurred",
          ],
        },
      };
    }
  }
}
