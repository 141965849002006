import { ACCOUNT_MANAGEMENT_API_URL } from "../../../../config";
import { VerifyDeviceUseCase } from "../../../../domain/use-cases/trusted-devices";
import { VerifyDeviceUseCaseImpl } from "../../../../infra/use-cases/trusted-devices/verify-device-usecase";
import { makeAccountIdHttpClientDecoratorFactory } from "../../decorators/make-account-id-http-client-decorator-factory";
import { makeApiUrlFactory } from "../../http";

const makeVerifyDeviceUseCaseFactory = (): VerifyDeviceUseCase => {
  return new VerifyDeviceUseCaseImpl(
    makeAccountIdHttpClientDecoratorFactory(),
    makeApiUrlFactory({
      path: "api/Device/Verify",
      baseUrl: ACCOUNT_MANAGEMENT_API_URL,
    }),
  );
};

const verifyDeviceUseCase = makeVerifyDeviceUseCaseFactory();

export async function verifyDevice(deviceId: string) {
  const verified = await verifyDeviceUseCase.execute({
    deviceId,
  });

  if (verified.isFailure()) {
    throw new Error(verified.value);
  }

  return verified.value;
}
