import React from "react";

export type RetryConfig = {
  limit?: number;
  timer?: number;
};

export type useRetryReturn = {
  retry: CallableFunction;
  retryError: CallableFunction;
};

export type useRetryFn = (
  cb: CallableFunction,
  retryConfig?: RetryConfig,
) => useRetryReturn;

const useRetry: useRetryFn = (callback, retryConfig) => {
  const retryLimit = retryConfig?.limit || 2;
  const intervalTimer = retryConfig?.timer || 300;

  let retryIntervalId: NodeJS.Timeout | null = null;
  let retryErrorIntervalId: NodeJS.Timeout | null = null;

  const retry = () => {
    let attempts = 0;

    if (retryIntervalId) clearInterval(retryIntervalId);

    retryIntervalId = setInterval(() => {
      if (attempts >= retryLimit) return clearInterval(retryIntervalId!);

      callback();

      attempts++;
    }, intervalTimer);
  };

  const retryError = () => {
    let attempts = 0;

    if (retryErrorIntervalId) clearInterval(retryErrorIntervalId);

    retryErrorIntervalId = setInterval(() => {
      if (attempts >= retryLimit) return clearInterval(retryErrorIntervalId!);

      try {
        callback();

        clearInterval(retryErrorIntervalId!);
      } catch (_) {
        attempts++;
      }
    }, intervalTimer);
  };

  React.useEffect(() => {
    return () => {
      if (retryIntervalId) clearInterval(retryIntervalId);
      if (retryErrorIntervalId) clearInterval(retryErrorIntervalId);
    };
  }, []);

  return {
    retry,
    retryError,
  };
};

export default useRetry;
