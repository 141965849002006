import axios, { AxiosError, AxiosResponse } from "axios";
import {
  ACCESS_CONTROL_API_URL,
  AGENDA_API_URL,
  CONTACTS_API_URL,
  DIGITAL_API_URL,
  FINANCE_PAYMENTS_API_URL,
  INTERNAL_STATEMENTS_API_TOKEN,
  INTERNAL_STATEMENTS_API_URL,
  LOGIN_API_URL,
  MANAGER_API_PASSWORD,
  MANAGER_API_URL,
  MANAGER_API_USERNAME,
  P2P_API_URL,
  PENDENCY_LIST_API_URL,
  PIX_API_URL,
  SPOT_API_URL,
  STATEMENTS_API_URL,
  TED_API_URL,
  TOKEN_API_URL,
} from "../config";
import { makeAmplifyAuthenticationService, signOutUseCase } from "../main";
import { useAccountStore } from "../presentation";
import { Login } from "../store/Login/types";
import { getVertical } from "../utils";
import { PostProfiles, Profiles } from "./types";

const cancelTokens: any[] = [];

const manager = axios.create({
  baseURL: MANAGER_API_URL,
});

const login = axios.create({
  baseURL: LOGIN_API_URL,
});

const digital = axios.create({
  baseURL: DIGITAL_API_URL,
});

const token = axios.create({
  baseURL: TOKEN_API_URL,
});

const statements = axios.create({
  baseURL: STATEMENTS_API_URL,
});

const contact = axios.create({
  baseURL: CONTACTS_API_URL,
});

const p2p = axios.create({
  baseURL: P2P_API_URL,
});

const ted = axios.create({
  baseURL: TED_API_URL,
});

const pix = axios.create({
  baseURL: PIX_API_URL,
});

const financePayments = axios.create({
  baseURL: FINANCE_PAYMENTS_API_URL,
});

const accessControl = axios.create({
  baseURL: ACCESS_CONTROL_API_URL,
});

const pendencyList = axios.create({
  baseURL: PENDENCY_LIST_API_URL,
});

const agenda = axios.create({
  baseURL: AGENDA_API_URL,
});

const spot = axios.create({
  baseURL: SPOT_API_URL,
});

const internalStatements = axios.create({
  baseURL: INTERNAL_STATEMENTS_API_URL,
  headers: {
    Authorization: `Bearer ${INTERNAL_STATEMENTS_API_TOKEN}`,
  },
});

const API = {
  manager,
  login,
  digital,
  token,
  statements,
  ted,
  pix,
  p2p,
  contact,
  financePayments,
  accessControl,
  pendencyList,
  spot,
  agenda,
  internalStatements,
};

[
  manager,
  pix,
  ted,
  login,
  statements,
  contact,
  p2p,
  financePayments,
  accessControl,
  pendencyList,
  spot,
  agenda,
].forEach((api) => {
  api.interceptors.request.use(async (config) => {
    const accessToken =
      await makeAmplifyAuthenticationService().getAccessToken();
    const accountId = useAccountStore.getState().currentAccountId;
    config.transformRequest = [
      (data, headers) => {
        if (headers) {
          headers["Content-Type"] = "application/json";
          headers.Authorization = `Bearer ${accessToken}`;
          headers["Account-id"] = accountId;
          headers["Cache-Control"] = "no-cache";
          headers["Access-Control-Allow-Origin"] = "*";
          headers["Cross-Domain"] = "true";
        }

        return JSON.stringify(data);
      },
    ];

    const source = axios.CancelToken.source();
    config.cancelToken = source.token;

    cancelTokens.push(source);

    return config;
  });
});

export function cancelAllRequests() {
  cancelTokens.forEach(source => source.cancel('Requisições canceladas no logout.'));
  cancelTokens.length = 0;
}

[digital, token].forEach((api) => {
  api.interceptors.request.use(async (config) => {
    config.transformRequest = [
      (data, headers) => {
        if (headers) {
          headers["Content-Type"] = "application/json";
          headers["Cache-Control"] = "no-cache";
          headers["Access-Control-Allow-Origin"] = "*";
          headers.crossDomain = "true";
          headers.username = MANAGER_API_USERNAME;
          headers.password = MANAGER_API_PASSWORD;
        }

        return JSON.stringify(data);
      },
    ];
    return config;
  });
});

[
  manager,
  digital,
  token,
  statements,
  ted,
  pix,
  p2p,
  contact,
  financePayments,
  spot,
  agenda,
].forEach((api) => {
  api.interceptors.response.use(parseResponse, parseResponseError);
});

function parseResponse(response: AxiosResponse) {
  return response;
}

export async function parseResponseError(
  error: AxiosError
): Promise<AxiosResponse> {
  if (error?.response?.status === 401 && getVertical() === "hyperlocal") {
    await signOutUseCase.execute();
  }

  return Promise.reject(error);
}

const Requests = {
  AccountBalance: async (guidAccount: string) =>
    await manager.get(`DigitalAccount/${guidAccount}/Balance`),
  companiesData: async (data: PostProfiles) =>
    await login.post<Profiles>(`auth/profiles`, data),
  loginSSO: async () => await login.post<Login>(`v2/Auth/login`),
};

export { API, Requests };
