import * as Sentry from "@sentry/react";
import type { Context, DebuggingService } from "./debugging-service";

const NODE_ENV = process.env.NODE_ENV || "";
const SENTRY_DSN = process.env.SENTRY_DSN || "";

export class SentryDebugging implements DebuggingService {
  private isSentryEnabled(): boolean {
    return NODE_ENV === "production" && Boolean(SENTRY_DSN);
  }

  private executeIfEnabled(callback: () => void) {
    if (this.isSentryEnabled()) return callback();
  }

  public init() {
    this.executeIfEnabled(() => {
      Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.browserProfilingIntegration(),
          Sentry.replayIntegration(),
        ],
        environment: NODE_ENV || "development",
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        profilesSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        beforeSend(event) {
          if (event.environment === "development") {
            return null;
          }

          return event;
        },
      });
    });
  }

  public close() {
    return this.executeIfEnabled(() => {
      Sentry.close();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public captureException(error: any, context: Context) {
    this.executeIfEnabled(() => {
      Sentry.captureException(error, {
        tags: context.tags || {},
        extra: context.extra || {},
      });
    });
  }
}
