import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";
import type { ListDeviceUseCaseRequest } from "../../../domain/use-cases/trusted-devices/list-devices-usecase";
import type { VerifyDeviceUseCaseRequest } from "../../../domain/use-cases/trusted-devices/verify-device-usecase";
import {
  DEFAULT_PAGE,
  listDevices,
  listDevicesInfiniteQuery,
  verifyDevice,
} from "../../../main/factories/use-cases/trusted-devices";

export const deviceQueryFactory = {
  default: ["device"] as const,
  verify: (params: VerifyDeviceUseCaseRequest) =>
    queryOptions({
      queryKey: [...deviceQueryFactory.default, "verify", params.deviceId],
      queryFn: () => verifyDevice(params.deviceId),
      staleTime: Infinity,
      enabled: Boolean(params.deviceId),
    }),
  list: (params: ListDeviceUseCaseRequest & { enabled?: boolean }) =>
    queryOptions({
      queryKey: [
        ...deviceQueryFactory.default,
        "list",
        params.personId,
        params.page,
        params.itemsPerPage,
        params.status,
      ],
      queryFn: () => listDevices(params),
      enabled: params.enabled,
    }),
  infiniteList: (
    params: Omit<ListDeviceUseCaseRequest, "page"> & { enabled?: boolean },
  ) =>
    infiniteQueryOptions({
      queryKey: [
        ...deviceQueryFactory.default,
        "infinite-list",
        params.personId,
        params.status,
      ],
      queryFn: ({ pageParam = "1" }) =>
        listDevicesInfiniteQuery({
          ...params,
          page: pageParam as never,
          status: params.status,
        }),
      initialPageParam: DEFAULT_PAGE,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      enabled: params.enabled,
    }),
};
