import { useCallback, useMemo, useReducer } from "react";
import { createMachine } from "./create-machine";
import { Action, MachineInstance, MachineSpec, SendFunction } from "./types";

export function useStateMachine<T extends MachineSpec<string, unknown>>(
  machineSpec: T,
) {
  type StateType = T["initial"];
  type ContextType = T["context"];
  type States = T["states"];

  const machine = useMemo(() => createMachine(machineSpec), [machineSpec]);

  const reducer = useCallback(
    (
      state: MachineInstance<StateType, ContextType>,
      action: Action<StateType, States, ContextType>,
    ): MachineInstance<StateType, ContextType> => {
      return machine.transition(state.state, action, state.context);
    },
    [machine],
  );

  const [currentState, dispatch] = useReducer(reducer, {
    state: machine.initialState,
    context: machine.context,
  });

  const send = useCallback<SendFunction<StateType, States, ContextType>>(
    (type, payload) => {
      dispatch({ type, payload });
    },
    [],
  );

  return [currentState, send] as const;
}
