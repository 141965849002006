export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export interface HttpRequest {
  url: string;
  method: HttpMethod;
  body?: any;
  headers?: Record<string, string>;
  params?: Record<string, string>;
  timeout?: number;
}

export const HTTP_STATUS_CODES = {
  ok: 200,
  created: 201,
  accepted: 202,
  noContent: 204,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  conflict: 409,
  internalServerError: 500,
  notImplemented: 501,
};

export type HttpStatusCode =
  (typeof HTTP_STATUS_CODES)[keyof typeof HTTP_STATUS_CODES];

export type HttpResponse<T = any> = {
  statusCode: number;
  headers?: Record<string, string>;
  body: T;
};
export interface HttpClient {
  request<T>(request: HttpRequest): Promise<HttpResponse<T>>;
}
