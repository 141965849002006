import axios from "axios";

export interface DeviceInfo {
  deviceId: string;
  latitude: string;
  longitude: string;
  ipAddress: string;
  deviceModel: string;
  operatingSystem: string;
}

export interface DeviceFingerprint {
  generateDeviceId(): Promise<string>;
  getDeviceInfo(): Promise<DeviceInfo>;
}

export interface ClientIpInfo {
  ip: string;
  ipType: "IPv4" | "IPv6";
  os: string;
  device: string;
  userAgent: string;
}

type DebugInfo = {
  UNMASKED_VENDOR_WEBGL: number;
  UNMASKED_RENDERER_WEBGL: number;
};

interface PluginInfo {
  name: string;
  filename: string;
  length: number;
}

/**
 * Service responsible for generating and managing device fingerprints.
 * This service collects various device characteristics to create a unique identifier
 * and provides additional device information.
 *
 * @example
 * ```typescript
 * const fingerprint = new DeviceFingerprintService('user123');
 * const deviceId = await fingerprint.generateDeviceId();
 * const deviceInfo = await fingerprint.getDeviceInfo();
 * ```
 */
export class DeviceFingerprintService implements DeviceFingerprint {
  /**
   * Creates an instance of DeviceFingerprintService.
   * @param email - The email of the user
   * @param accountId - The ID of the user
   */
  constructor(
    private readonly accountId: string,
    private readonly email: string,
  ) {}

  /**
   * Generates a unique device identifier by combining various device characteristics.
   * The identifier is created using two hashes: a primary hash based on device features
   * and a secondary hash based on stable device characteristics.
   *
   * @returns Promise resolving to a 32-character device identifier
   */
  async generateDeviceId(): Promise<string> {
    const deviceFeatures = await this.collectDeviceFeatures();

    const deviceData = {
      accountId: this.accountId,
      email: this.email,
      features: deviceFeatures,
    };

    const primaryHash = await this.hashData(JSON.stringify(deviceData));

    const stableFeatures = {
      screen: this.getScreenSpec(),
      gpu: await this.getGPUInfo(),
      hardware: {
        cores: navigator.hardwareConcurrency || 0,
        memory: (navigator as any).deviceMemory || 0,
        platform: navigator.platform,
      },
      audio: await this.getAudioFingerprint(),
      canvas: await this.getCanvasFingerprint(),
    };

    const secondaryHash = await this.hashData(JSON.stringify(stableFeatures));

    return `${primaryHash.slice(0, 24)}${secondaryHash.slice(-8)}`;
  }

  /**
   * Collects various device features including navigator, plugins, screen,
   * timezone, camera, canvas, audio, and GPU information.
   *
   * @returns Promise resolving to an object containing device features
   * @private
   */
  private async collectDeviceFeatures() {
    return {
      navigator: {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        vendor: navigator.vendor,
        hardwareConcurrency: navigator.hardwareConcurrency,
        deviceMemory: (navigator as any).deviceMemory,
      },
      plugins: this.getPluginsInfo(),
      screen: this.getScreenSpec(),
      timezone: {
        offset: new Date().getTimezoneOffset(),
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      canvas: await this.getCanvasFingerprint(),
      audio: await this.getAudioFingerprint(),
      gpu: await this.getGPUInfo(),
    };
  }

  /**
   * Hashes the provided data using SHA-256 algorithm.
   *
   * @param data - String data to be hashed
   * @returns Promise resolving to a hexadecimal hash string
   * @private
   */
  private async hashData(data: string): Promise<string> {
    try {
      const encoder = new TextEncoder();
      const encoded = encoder.encode(data);
      const hashBuffer = await crypto.subtle.digest("SHA-256", encoded);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    } catch (error) {
      console.error("Error hashing data:", error);
      return `hash-error-${data.length}`;
    }
  }

  /**
   * Retrieves information about installed browser plugins.
   *
   * @returns Array of plugin information objects
   * @private
   */
  private getPluginsInfo(): PluginInfo[] {
    if (!navigator.plugins) return [];

    return Array.from(navigator.plugins).map((plugin) => ({
      name: plugin.name,
      filename: plugin.filename,
      length: plugin.length,
    }));
  }

  /**
   * Gets screen specifications including width, height, color depth, and pixel ratio.
   *
   * @returns String containing screen specifications in format "width,height,colorDepth,pixelRatio"
   * @private
   */
  private getScreenSpec(): string {
    return `${window.screen.width},${window.screen.height},${window.screen.colorDepth},${window.devicePixelRatio}`;
  }

  /**
   * Generates a fingerprint using HTML Canvas.
   * Creates a unique image and converts it to a data URL.
   *
   * @returns Promise resolving to canvas data URL or status message
   * @private
   */
  private async getCanvasFingerprint(): Promise<string> {
    if (!("HTMLCanvasElement" in window)) {
      return "canvas-not-supported";
    }

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return "canvas-not-available";

    canvas.width = 200;
    canvas.height = 200;

    ctx.textBaseline = "alphabetic";
    ctx.fillStyle = "#f60";
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = "#069";
    ctx.font = "16px Arial";
    ctx.fillText("Fingerprint", 2, 15);
    ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
    ctx.font = "bold 18px Times New Roman";
    ctx.fillText("Canvas", 4, 45);

    ctx.beginPath();
    ctx.arc(50, 50, 30, 0, Math.PI * 2);
    ctx.fill();

    return canvas.toDataURL();
  }

  /**
   * Generates an audio fingerprint using Web Audio API.
   *
   * @returns Promise resolving to audio characteristics string or status message
   * @private
   */
  private async getAudioFingerprint(): Promise<string> {
    if (!("AudioContext" in window || "webkitAudioContext" in window)) {
      return "audio-not-supported";
    }

    try {
      const AudioCtx =
        window.AudioContext || (window as any).webkitAudioContext;
      const audioContext = new AudioCtx();
      const oscillator = audioContext.createOscillator();
      const analyser = audioContext.createAnalyser();

      oscillator.connect(analyser);
      oscillator.frequency.setValueAtTime(1000, audioContext.currentTime);

      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(dataArray);

      await audioContext.close();
      return Array.from(dataArray.slice(0, 16)).join(",");
    } catch {
      return "audio-not-available";
    }
  }

  /**
   * Retrieves GPU information using WebGL.
   *
   * @returns Promise resolving to GPU vendor and renderer string or status message
   * @protected
   */
  protected async getGPUInfo(): Promise<string> {
    if (!("HTMLCanvasElement" in window)) {
      return "webgl-not-supported";
    }

    const canvas = document.createElement("canvas");
    const gl = (canvas.getContext("webgl") ||
      canvas.getContext("experimental-webgl")) as WebGLRenderingContext | null;

    if (!gl) return "webgl-not-available";

    const debugInfo = gl.getExtension(
      "WEBGL_debug_renderer_info",
    ) as DebugInfo | null;

    if (!debugInfo) return "debug-info-not-available";

    const vendor =
      gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) || "unknown";
    const renderer =
      gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) || "unknown";

    return `${vendor}-${renderer}`;
  }

  /**
   * Collects comprehensive device information including device ID, location,
   * IP address
   *
   * @returns Promise resolving to DeviceInfo object
   */
  async getDeviceInfo(): Promise<DeviceInfo> {
    const ipInfo = await this.getClientIpInfo();
    const deviceId = await this.generateDeviceId();

    return {
      deviceId,
      latitude: ipInfo?.latitude || "",
      longitude: ipInfo?.longitude || "",
      ipAddress: ipInfo?.ip || "",
      deviceModel: navigator.platform || "",
      operatingSystem: this.getOperatingSystem(),
    };
  }

  /**
   * Determines the operating system based on user agent and platform information.
   *
   * @returns Operating system name string
   * @private
   */

  private getOperatingSystem(): string {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;

    if (/Win/.test(platform)) return "Windows";
    if (/Mac/.test(platform)) return "MacOS";
    if (/Linux/.test(platform)) return "Linux";
    if (/Android/.test(userAgent)) return "Android";
    if (/iPhone|iPad|iPod/.test(userAgent)) return "iOS";

    return "Unknown";
  }

  /**
   * Retrieves client IP information from external API.
   *
   * @returns Promise resolving to ClientIpInfo object or null if request fails
   * @private
   */
  private async getClientIpInfo() {
    try {
      const response = await axios.get<{
        ip: string;
        type: "IPv4" | "IPv6";
        continent: string;
        country: string;
        city: string;
        latitude: number;
        longitude: number;
        isp: string;
      }>("https://ipwhois.app/json/");

      return {
        ip: response.data.ip,
        latitude: response.data.latitude.toString(),
        longitude: response.data.longitude.toString(),
      };
    } catch (error) {
      console.error("Error fetching client IP information:", error);
      return null;
    }
  }
}
