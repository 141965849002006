import { getBalanceRoutesData } from "./Balance";
import type { BalanceRoutes } from "./Balance/types";
import { getCardsRoutesData } from "./Cards";
import type { CardsRoutes } from "./Cards/types";
import { getCashOutRoutesData } from "./CashOut";
import type { CashOutRoutes } from "./CashOut/types";
import { getChargeRoutesData } from "./Charge";
import type { ChargeRoutes } from "./Charge/types";
import { getDashboardRoutesData } from "./Dashboard";
import type { DashboardRoutes } from "./Dashboard/types";
import { getPixRoutesData } from "./Pix";
import type { PixRoutes } from "./Pix/types";
import { getReceiptRoutesData } from "./Receipt";
import type { ReceiptRoutes } from "./Receipt/types";
import { getReceivedRoutesData } from "./Received";
import type { ReceivedRoutes } from "./Received/types";
import { getSalesRoutesData } from "./Sales";
import type { SalesRoutes } from "./Sales/types";
import { getStatementsRoutesData } from "./Statements";
import type { StatementsRoutes } from "./Statements/types";
import { getTokenRoutesData } from "./Token";
import type { TokenRoutes } from "./Token/types";
import { getTransfersRoutesData } from "./Transfers";
import type { TransfersRoutes } from "./Transfers/types";

export type AllRoutes = {
  cardRoutes: CardsRoutes;
  balanceRoutes: BalanceRoutes;
  cashOutRoutes: CashOutRoutes;
  dashboardRoutes: DashboardRoutes;
  pixRoutes: PixRoutes;
  receiptRoutes: ReceiptRoutes;
  salesRoutes: SalesRoutes;
  statementsRoutes: StatementsRoutes;
  transfersRoutes: TransfersRoutes;
  tokenRoutes: TokenRoutes;
  receivedRoutes: ReceivedRoutes;
  chargeRoutes: ChargeRoutes;
};

export const getAllRoutes = (): AllRoutes => {
  const balanceRoutes = getBalanceRoutesData();
  const cashOutRoutes = getCashOutRoutesData();
  const dashboardRoutes = getDashboardRoutesData();
  const pixRoutes = getPixRoutesData();
  const receiptRoutes = getReceiptRoutesData();
  const salesRoutes = getSalesRoutesData();
  const statementsRoutes = getStatementsRoutesData();
  const transfersRoutes = getTransfersRoutesData();
  const tokenRoutes = getTokenRoutesData();
  const receivedRoutes = getReceivedRoutesData();
  const cardRoutes = getCardsRoutesData();
  const chargeRoutes = getChargeRoutesData();

  return {
    balanceRoutes,
    cashOutRoutes,
    dashboardRoutes,
    pixRoutes,
    receiptRoutes,
    salesRoutes,
    statementsRoutes,
    transfersRoutes,
    tokenRoutes,
    receivedRoutes,
    cardRoutes,
    chargeRoutes,
  };
};
