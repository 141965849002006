// Anything exported from this file is importable by other in-browser modules.
import { applyTheme } from "@hyperlocal/vital-theme";
import type { SetUpTOTPOutput } from "aws-amplify/auth";
import { Caf } from "./components/CAF";
import { HeaderEvent } from "./components/Header";
import { IHeader } from "./components/Header/types";
import * as Validator from "./components/Validator";
import { UserAccount } from "./domain";
import * as Hooks from "./Hooks";
import { AmplifyAuthenticationService } from "./infra";
import {
  getAccountUseCase,
  makeAccountStoreFactory,
  makeAmplifyAuthenticationService,
  makeUserStoreFactory,
} from "./main";
import { useUserStore } from "./presentation/hooks/user-store";
import { Profiles } from "./services/types";
import {
  PixRoutes,
  SalesRoutes,
  setLogin,
  setUser,
  StatementsRoutes,
} from "./store";
import { Boleto } from "./store/Boleto/Payment/types";
import { Login } from "./store/Login/types";
import { P2P } from "./store/P2P/Payment/types";
import { Charge } from "./store/Pix/Charge/types";
import { Pix } from "./store/Pix/Payment/types";
import { ReceiptPayment } from "./store/Receipt/Payment/types";
import { BalanceRoutes } from "./store/Routes/Balance/types";
import { DashboardRoutes } from "./store/Routes/Dashboard/types";
import { Ted } from "./store/Ted/Payment/types";
import { Token } from "./store/Token/types";
import { Transfer } from "./store/Transfer/types";
import { getVertical } from "./utils";
import { formatCurrency } from "./utils/currency/format";
import { formatDate } from "./utils/date/format";
import {
  EventName,
  EventValue,
  getEventIdentifier,
  getEventKey,
} from "./utils/eventIdentifier";

export { requestBalance } from "./components/Balance";
export * from "./domain";
export * from "./lib";
export * from "./main";
export * from "./presentation";
export * from "./services/index";
export * from "./store";
export * from "./store/Routes";
export * from "./utils";
export { getVerticalBankName } from "./utils/get-vertical-bank-name";
export { hasPermission } from "./utils/hasPermission";
export { removeAccent } from "./utils/string/removeAccent";
export { themeProvider } from "./utils/styledVerticalTheme";
export { Caf, Hooks, Validator };
export { formatCurrency, formatDate, getEventIdentifier, getEventKey };

export type Types = {
  IHeader: IHeader;
  ILogin: Login;
  Profiles: Profiles;
  IBoleto: Boleto;
  IToken: Token;
  IPix: Pix;
  IReceiptPayment: ReceiptPayment;
  ITed: Ted;
  IP2P: P2P;
  ITransfer: Transfer;
  ICharge: Charge;

  IBalanceRoutes: BalanceRoutes;
  IDashboardRoutes: DashboardRoutes;
  ISalesRoutes: SalesRoutes;
  IPixRoutes: PixRoutes;
  IStatementsRoutes: StatementsRoutes;

  ICustomEventName: EventName;
  ICustomEventValue: EventValue;
};

export async function getAuth() {
  const login: Login = {
    authId: "",
    authToken: "",
    cpf: "94024728008",
    email: "",
    name: "",
    area_code: "",
    cellphone: "",
    gender: "",
    guid_user:
      makeAccountStoreFactory().account?.personInfoResponse?.customerId,
    last_access_date: 0,
    lastAccessDate: "",
    lockoutEnabled: false,
    lockoutEndDateUTC: "",
    PF_PJ: "",
    show_release_note: false,
    status: "",
    status_password: false,
    token_mail: false,
    token_sms: false,
  };

  setLogin(login);

  const accounts = makeUserStoreFactory().user?.accounts || [];
  const Customers: Profiles["Customers"] =
    [] as unknown as Profiles["Customers"];

  await Promise.allSettled(
    accounts.map(async (account: UserAccount) => {
      const accountsResponse = await getAccountUseCase.execute({
        accountId: account.accountId,
      });
      if (accountsResponse.isSuccess()) {
        Customers.push({
          cpf: accountsResponse.value.personInfoResponse.document,
          PF_PJ: "",
          last_access_date: "",
          guid_account: account.accountId,
          account_code: account.accountNumber,
          account_digit: account.accountDigit,
          Alias: "",
          block_date: "",
          block_reason: "",
          caf_type: "",
          cnpj: accountsResponse.value.companyInfoResponse?.document || "",
          guid_customer: accountsResponse.value.personInfoResponse.customerId,
          Access_Profiles: {
            type: "Access_Profiles",
            user_type: "Customer",
          },
          name: accountsResponse.value.personInfoResponse.name,
          enterprise_name: "",
          Products: [],
          registration_date: "",
          status_account: "B",
        });
      }
    }),
  );

  const profiles: Profiles = {
    guid_user: useUserStore.getState().user?.userId || "",
    name: makeAccountStoreFactory().account?.personInfoResponse?.name || "",
    Customers,
  };
  const guidAccount = makeAccountStoreFactory().currentAccountId;

  setUser({ login, profiles });

  return {
    login,
    profiles,
    guid_account: guidAccount,
    hasCustomers: true,
  };
}

export function setHeader(props: IHeader) {
  HeaderEvent(props);
}

applyTheme(getVertical());

export const {
  signIn,
  signOut,
  updateMFAPreference,
  verifyTOTPSetup,
  confirmSignIn,
  fetchAuthSession,
  confirmResetPassword,
  resetPassword,
  updatePassword,
  configureAuth,
  isAuthenticated,
}: AmplifyAuthenticationService = makeAmplifyAuthenticationService();
export const setUpTOTP: () => Promise<SetUpTOTPOutput> =
  makeAmplifyAuthenticationService().setUpTOTP;
