import { ACCOUNT_MANAGEMENT_API_URL } from "../../../../config";
import type {
  ListDeviceUseCase,
  ListDeviceUseCaseRequest,
} from "../../../../domain/use-cases/trusted-devices/list-devices-usecase";
import { ListDeviceUseCaseImpl } from "../../../../infra/use-cases/trusted-devices/list-devices-usecase";
import { makeAccountIdHttpClientDecoratorFactory } from "../../decorators/make-account-id-http-client-decorator-factory";
import { makeApiUrlFactory } from "../../http";

export const DEFAULT_PAGE_SIZE = "10";
export const DEFAULT_PAGE = "1";

const makeListDevicesUseCaseFactory = (): ListDeviceUseCase => {
  return new ListDeviceUseCaseImpl(
    makeAccountIdHttpClientDecoratorFactory(),
    makeApiUrlFactory({
      path: "api/Device/ListDevices",
      baseUrl: ACCOUNT_MANAGEMENT_API_URL,
    }),
  );
};

export async function listDevices({
  page = DEFAULT_PAGE,
  itemsPerPage = DEFAULT_PAGE_SIZE,
  personId,
  status,
}: ListDeviceUseCaseRequest) {
  const listDevicesUseCase = makeListDevicesUseCaseFactory();
  const response = await listDevicesUseCase.execute({
    page,
    itemsPerPage,
    personId,
    status,
  });

  if (!response.success) {
    throw new Error(response.error.errorMessages.join(", "));
  }

  return response.data;
}

export async function listDevicesInfiniteQuery({
  page = DEFAULT_PAGE,
  itemsPerPage = DEFAULT_PAGE_SIZE,
  personId,
}: ListDeviceUseCaseRequest) {
  const response = await listDevices({
    page,
    itemsPerPage,
    personId,
  });

  return {
    ...response,
    nextPage:
      response.currentPage < response.pageCount
        ? String(response.currentPage + 1)
        : undefined,
  };
}
