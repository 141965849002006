import { ACCOUNT_MANAGEMENT_API_URL } from "../../../../config";
import type {
  UpdateDeviceUseCase,
  UpdateDeviceUseCaseRequest,
} from "../../../../domain/use-cases/trusted-devices";
import { UpdateDeviceUseCaseImpl } from "../../../../infra/use-cases/trusted-devices";
import { makeAccountIdHttpClientDecoratorFactory } from "../../decorators/make-account-id-http-client-decorator-factory";
import { makeApiUrlFactory } from "../../http/api-url-factory";

const makeUpdateDeviceUseCaseFactory = (): UpdateDeviceUseCase => {
  return new UpdateDeviceUseCaseImpl(
    makeAccountIdHttpClientDecoratorFactory(),
    makeApiUrlFactory({
      path: "api/Device",
      baseUrl: ACCOUNT_MANAGEMENT_API_URL,
    }),
  );
};

export async function updateDevice(input: UpdateDeviceUseCaseRequest) {
  const blockDeviceUseCase = makeUpdateDeviceUseCaseFactory();
  return blockDeviceUseCase.execute(input);
}
