import { DeviceFingerprintService } from "../../../infra/services/device-fingerprint/device-fingerprint-service";
import { useAccountStore } from "../../../presentation/hooks/account-store";
import { useUserStore } from "../../../presentation/hooks/user-store";

export function makeDeviceFingerprintFactory() {
  const email = useUserStore.getState().user?.email;
  const accountId = useAccountStore.getState().currentAccountId;
  return new DeviceFingerprintService(accountId, email as string);
}

export const deviceFingerprintService = () => makeDeviceFingerprintFactory();
