import { useEffect, useState } from "react";
import { deviceFingerprintService } from "../../../main/factories/services";
import { useAccountStore } from "../account-store";
import { useUserStore } from "../user-store";

interface DeviceInfo {
  deviceId: string;
  latitude: string;
  longitude: string;
  ipAddress: string;
  deviceModel: string;
  operatingSystem: string;
}

export function useDeviceFingerprint(): DeviceInfo | null {
  const [deviceFingerprint, setDeviceFingerprint] = useState<DeviceInfo | null>(
    null,
  );

  const accountId = useAccountStore((state) => state.currentAccountId);
  const email = useUserStore((state) => state.user?.email);

  useEffect(() => {
    const fetchDeviceFingerprint = async () => {
      try {
        const newFingerprint = await deviceFingerprintService().getDeviceInfo();
        setDeviceFingerprint(newFingerprint);
        sessionStorage.setItem("deviceFingerprint", newFingerprint.deviceId);
      } catch (error) {
        console.error("Error generating device fingerprint:", error);
        setDeviceFingerprint(null);
      }
    };

    if (accountId && email) {
      fetchDeviceFingerprint();
    }
  }, [accountId, email]); // Re-
  return deviceFingerprint;
}
