import { Action, MachineInstance, MachineSpec, MachineState } from "./types";

export function createMachine<T extends string, C>(spec: MachineSpec<T, C>) {
  const { initial, states, context = {} as C } = spec;
  return {
    initialState: initial,
    states,
    context,
    transition: (
      currentState: T,
      action: Action<T, MachineState<T>, C>,
      currentContext: C,
    ): MachineInstance<T, C> => {
      const nextState = states[currentState][action.type];
      const newContext = action.payload
        ? { ...currentContext, ...action.payload }
        : currentContext;
      return {
        state: nextState ?? currentState,
        context: newContext,
      };
    },
  };
}
