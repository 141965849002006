import { create } from "zustand";
import { TrustedDeviceStore } from "../../../domain";

export function makeTrustedDeviceStoreFactory() {
  return create<TrustedDeviceStore>((set) => ({
    step: "idle",
    deviceAlias: "",
    error: null,
    setStep: (step) => set({ step }),
    setDeviceAlias: (deviceAlias) => set({ deviceAlias }),
    setError: (error) => set({ error }),
    reset: () => set({ step: "idle", deviceAlias: "", error: null }),
  }));
}
