import type {
  UpdateDeviceUseCase,
  UpdateDeviceUseCaseErrorResponse,
  UpdateDeviceUseCaseRequest,
  UpdateDeviceUseCaseResponse,
  UpdateDeviceUseCaseSuccessResponse,
} from "../../../domain/use-cases/trusted-devices";
import type { HttpClient } from "../../http/http-client";

export class UpdateDeviceUseCaseImpl implements UpdateDeviceUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
  ) {}

  async execute(
    body: UpdateDeviceUseCaseRequest,
  ): Promise<UpdateDeviceUseCaseResponse> {
    const response = await this.httpClient.request({
      method: "PUT",
      url: this.url,
      body,
    });

    if (response.statusCode >= 400) {
      return {
        error: response.body as UpdateDeviceUseCaseErrorResponse,
        success: false,
      };
    }
    return {
      success: true,
      data: response.body as UpdateDeviceUseCaseSuccessResponse,
    };
  }
}
