import {
  DebuggingService,
  DebuggingServiceImpl,
  RouteBasedDebuggingService,
} from "../../../infra/services/debugging";
import { SentryDebugging } from "../../../infra/services/debugging/sentry";
import { getAllRoutes } from "../../../store/Routes/get-all-routes";

type DebuggingServiceWithRouting = DebuggingService &
  RouteBasedDebuggingService;

let instance: DebuggingServiceWithRouting | null = null;

export function makeSentryDebuggingServiceFactory(): DebuggingServiceWithRouting {
  if (!instance) {
    const applicationRoutes = getAllRoutes();
    const sentryDebuggingService = new SentryDebugging();
    const debuggingServiceImpl = new DebuggingServiceImpl(
      sentryDebuggingService,
      applicationRoutes,
    );

    debuggingServiceImpl.listenToRouteChanges();

    instance = debuggingServiceImpl;
  }

  return instance;
}

export const debuggingService = makeSentryDebuggingServiceFactory();
