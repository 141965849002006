import axios, { AxiosInstance, AxiosResponse } from "axios";
import { parseResponseError } from "../../services";
import { HttpClient, HttpRequest, HttpResponse } from "./http-client";

export class AxiosHttpClient implements HttpClient {
  private static instance: AxiosInstance;

  constructor() {
    if (!AxiosHttpClient.instance) {
      AxiosHttpClient.instance = axios.create();

      AxiosHttpClient.instance.interceptors.response.use(
        (response: AxiosResponse) => response,
        parseResponseError,
      );
    }
  }

  async request<T>(request: HttpRequest): Promise<HttpResponse<T>> {
    let response: AxiosResponse;
    try {
      response = await AxiosHttpClient.instance({
        url: request.url,
        method: request.method,
        data: request.body,
        headers: request.headers,
        params: request.params,
      });

      return {
        statusCode: response.status,
        body: response.data,
        headers: response.headers as Record<string, string>,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      response = error.response;
    }
    return {
      statusCode: response.status,
      body: response.data,
    };
  }
}
