import { ACCOUNT_MANAGEMENT_API_URL } from "../../../../config";
import { VerifyDeviceUseCase } from "../../../../domain/use-cases/device-fingerprint";
import { VerifyDeviceUseCaseImpl } from "../../../../infra/use-cases/device-fingerprint/verify-device-usecase";
import { makeAccountIdHttpClientDecoratorFactory } from "../../decorators/make-account-id-http-client-decorator-factory";
import { makeApiUrlFactory } from "../../http";

const makeVerifyDeviceUseCaseFactory = (): VerifyDeviceUseCase => {
  return new VerifyDeviceUseCaseImpl(
    makeAccountIdHttpClientDecoratorFactory(),
    makeApiUrlFactory({
      path: "api/Device/Verify",
      baseUrl: ACCOUNT_MANAGEMENT_API_URL,
    }),
  );
};

export const verifyDeviceUseCase = makeVerifyDeviceUseCaseFactory();
